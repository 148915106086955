.home-page {
    height: 100%;
}
.terminal {
    width: 100%;
    height: 100%;
    background-color: rgb(50, 50, 70);
    box-sizing: border-box;
    cursor: default;
    overflow-x: none;
    overflow-y: auto;
}

.terminal-command-container {
    padding: 0.25rem;
}

.terminal-command {
    display: flex;
    align-items: center;
    padding: 0.1rem 0;
}

.terminal-input {
    margin-left: 0.5rem;
    color: white;
    white-space: pre;
}

.terminal-input > span {
    background-color: rgb(255, 255, 255, 0.9);
    color: black;
}

.terminal-mobile {
    color: white;
}

.terminal-output {
    color: white;
}

.terminal-output * {
    overflow-wrap: break-word;
}

.terminal-output  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin: .2rem .5rem;
}

.terminal-output p {
    margin: .2rem 0;
}

a {
    color: white;
}

a:visited {
    color: white;
}

td {
    padding: 0 .25rem;
}

.terminal-output > a:not-v

p {
    padding: 0;
    margin: 0;
}

.terminal-output  code {
    color: white;
    background-color: rgb(30, 30, 50);
    border-radius: 0.3rem;
    padding: 0.3rem;
}

.user {
    color: rgb(10, 150, 30);
}

.cwd {
    color: rgb(0, 150, 200);
}

.white {
    color: white;
    margin-left: 1px;
    font-weight: 200;
}

.command-output {
    color: white;
    white-space: pre-wrap;
}

.os-main {
    display: flex;
    height: 100%;
    width: 100%;
    /* background-color: blue; */
}