body {
    padding: 0;
    margin: 0;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    background-color: rgb(50, 50, 50);
    box-sizing: border-box;
    padding: 0.5rem;
    color: white;
    position: sticky;
    top: 0;
}

.right-command-bar {
    display: flex;
    gap: 0.75rem;
}

.middle-bar {
    font-weight: 500;
}

.cmd-btn {
    outline: black 1px solid;
    border: none;
    border-radius: 0.2rem;
    background-color: rgb(70, 70, 70);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-block: 1px;
    padding-inline: 6px;
    box-sizing: border-box;
    margin: 0em;
    width: 37px;
    height: 30px;
}

.cmd-btn:hover {
    background-color: rgb(80, 80, 80);
}
.circle-cmd-btn {
    background-color: rgb(70, 70, 70);
    color: white;
    border-radius: 50%;
    padding: 0;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
}

.circle-cmd-btn:hover {
    background-color: rgb(80, 80, 80);
}

.layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-grow;
    background-color: white;
    width: 100%;
    height: 100vh;
}
